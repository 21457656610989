import { defineStore } from 'pinia'
import moment, { type Moment } from 'moment-timezone'
import httpClient from '@/httpClient/index.ts'
import ErrorHelper from '@/exports/error.ts'
import type {
  IBreadcrumbEvent,
  IDownload,
  IErrorModal,
  IIndexedDBStatus,
  ILayoutOptions,
  IndexedDBWorkerRequest,
  IPageHeader,
  ISubmitError,
  IToast,
  UpgradeFeatureName,
  WorkerRequest
} from '@/models/interfaces.ts'
import type { ICommonState } from '@/models/stores/common.ts'
import constants from '@/exports/constants.ts'
import WorkerService from '@/services/webWorkerService'
import fileDownloaderWorker from '../workers/fileDownloader?worker&url'
import { DownloadStatus } from '@/models/enums.ts'
import { useModals } from '@/composables/useModal/useModal.ts'
import i18n from '@/i18n'
import helper from '@/exports/helper'
import indexedDBWorker from '../workers/indexedDB?worker&url'
import storageService from '@/services/storageService.ts'
import {
  fetchAndActivate,
  getAllFeatureFlagValues
} from '@/services/firebase.ts'
import type { ApplicationInsights } from '@microsoft/applicationinsights-web'

const initialState = (): ICommonState => ({
  loading: false,
  badgeCounts: {
    newMessageCount: 0,
    newCalendarEventCount: 0,
    newInfoLibraryCardCount: 0,
    newAccountablePaymentCount: 0,
    unseenVoicemailCount: 0
  },
  activityCounts: {
    calendarEventCount: 0,
    infoLibraryCardCount: 0,
    callCount: 0,
    journalEntryCount: 0
  },
  pageHeaders: {
    page: '',
    pageName: '',
    pageNameMobile: '',
    pageIcon: '',
    searchEnabled: false,
    searching: false,
    pageData: {},
    welcomePageName: '',
    pageBreadcrumbs: []
  },
  layoutOptions: {
    pageType: constants.pageType.list,
    headerText: '',
    subHeaderText: '',
    showBackbutton: false,
    showPaymentButtons: false,
    backButtonRouteName: undefined,
    backButtonLabel: 'go back',
    showUpgradeBanner: false,
    showAddNewButton: true,
    showHeaderAvatar: false,
    showCalendarNotificationsButton: false,
    showInfoButton: false,
    upgradeBannerForewordMessage:
      'Attachments are archived seven days after they are uploaded.',
    upgradeBannerMessageTier0: 'for unlimited access to all attachments.',
    upgradeBannerMessageTier1: undefined,
    showShadow: true,
    showServiceMark: false,
    showMobileHeader: true
  },
  fullUserInfo: { traceUser: false },
  fullUserInfoInit: false,
  windowWidth: window.innerWidth,
  layoutCommonDataLoaded: false,
  setObserverVal: false,
  newVersionAvailable: false,
  isOnline: 'onLine' in navigator && navigator.onLine,
  submitError: { error: false, message: '' },
  appSettings: null,
  abortController: null,
  pendingOperations: [],
  toast: {
    showToast: false,
    type: 'success',
    text: '',
    showCloseButton: true
  },
  toastList: [] as IToast[],
  errorModal: {
    showErrorModal: false,
    title: undefined,
    errorMessage: undefined,
    showFixError: false,
    showGenericError: false
  },
  showingNoCount: false,
  adsenseDesktopKey: '',
  adsenseMobileKey: '',
  adblockModalShown: false,
  vue2AppUrl: `${import.meta.env.VITE_APP_OLD_APP_BASE_URL}`,
  showInfoModal: false,
  welcomePagesViewed: {
    messages: false,
    attachments: false,
    calendar: false,
    moneyTransfer: false,
    infoLibrary: false,
    journal: false,
    records: false,
    vault: false,
    calling: false
  },
  upgradeCTA: {
    messages: {
      isClosed: false,
      closedWhen: ''
    },
    attachments: {
      isClosed: false,
      closedWhen: ''
    },
    records: {
      isClosed: false,
      closedWhen: ''
    }
  },
  searchMode: false,
  showBetaToggle: false,
  userLocale: '',
  fbAuthToken: '',
  searchTerm: '',
  downloads: [],
  mobileHeaderMounted: false,
  indexedDBStatuses: [],
  featureFlags: null,
  newFeatureFlagValues: false,
  doAddNew: null,
  appInsightsInstance: null
})

const paths: string[] = ['welcomePagesViewed', 'upgradeCTA']

export const useCommonStore = defineStore('common', {
  state: initialState,
  persist: {
    paths: paths
  },
  getters: {
    getDateTimeSettings: (state) => {
      return {
        dateFormatID: state.fullUserInfo.dateFormatId,
        formatName: state.fullUserInfo.dateFormatName,
        shortDateFormat: state.fullUserInfo.shortDateFormat ?? '',
        timeZoneID: state.fullUserInfo.timeZoneId,
        timeZoneIdentifier: state.fullUserInfo.timeZoneIdentifierString,
        timeZoneNameForMomentJs:
          state.fullUserInfo.timeZoneNameForMomentJs ?? '',
        userID: state.fullUserInfo.userId
      }
    },
    formatTime: () => {
      return (value: string) => {
        return moment(value).format('h:mm A')
      }
    },
    isDesktopWidth: (state) => {
      return state.windowWidth >= 768
    },
    isMobileWidth: (state) => {
      return state.windowWidth < 768
    },
    isTabletWidth: (state) => {
      return state.windowWidth >= 768 && state.windowWidth <= 1024
    },
    getWebsiteBaseUrl: () => {
      return import.meta.env.VITE_APP_WEBSITE_BASE_URL
    },
    isProd: () => {
      return import.meta.env.VITE_NODE_ENV === 'production'
    },
    isQa1: () => {
      return import.meta.env.VITE_NODE_ENV === 'qa1'
    },
    isDev: () => {
      return import.meta.env.VITE_NODE_ENV === 'development'
    },
    getCDNImageUrl(): string {
      return import.meta.env.VITE_APP_IMAGE_CDN_URL
    },
    formatCurrency: () => {
      return (value: number | undefined) =>
        `$${(value || 0 / 1)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`
    },
    formatCurrencyInteger: () => {
      return (value: number | undefined) => `$${value?.toLocaleString()}`
    },
    isUserMatched: (state) => {
      return (
        state.fullUserInfo?.caseId != undefined &&
        state.fullUserInfo?.caseId > 0
      )
    },
    isMobileDevice: () => {
      // latest regex test for mobile devices. May need updated from time to time
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    isTouchDevice: () =>
      !!(navigator.maxTouchPoints & 0xff) ||
      'ontouchstart' in document.documentElement,
    currentBreadcrumbFolder: (state) =>
      state.pageHeaders.pageBreadcrumbs
        ?.slice()
        .reverse()
        .find((c) => c.breadcrumbData?.isFolder),
    inProgressDownloads: (state) =>
      state.downloads.filter((d) => d.status == DownloadStatus.Downloading),
    indexedDBKey: (state) =>
      `${state.fullUserInfo.caseId}_${state.fullUserInfo.userId}`
  },
  actions: {
    reset() {
      Object.assign(
        this.$state,
        helper.omit(initialState(), paths as (keyof ICommonState)[])
      )
    },
    getUsersFirstNameFromUserId(userId: number) {
      return userId == this.fullUserInfo.userId
        ? this.fullUserInfo.firstName
        : this.fullUserInfo.coparentFirstName
    },
    async fetchBadgeCounts() {
      try {
        const endpoint = '/web/api/Badge/GetBadgeCounts'
        const response = await httpClient.get(endpoint, {
          headers: { 'api-version': '2' }
        })
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)
        const counts = response.data.value

        this.badgeCounts = {
          newMessageCount: counts.newMessageCount,
          newCalendarEventCount: counts.newCalendarEventCount,
          newInfoLibraryCardCount: counts.newInfoLibraryCardCount,
          newAccountablePaymentCount: counts.newAccountablePaymentCount,
          unseenVoicemailCount: counts.unseenVoicemailCount
        }

        return true
      } catch (e) {
        ErrorHelper.handleError(e, 'getBadgeCounts')
        return false
      }
    },
    async fetchActivityCounts() {
      try {
        const endpoint = '/web/api/Badge/GetActivityCounts'
        const response = await httpClient.get(endpoint, {
          headers: { 'api-version': '2' }
        })
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)
        const counts = response.data.value
        this.activityCounts = {
          calendarEventCount: counts.calendarEventCount,
          callCount: counts.callCount,
          infoLibraryCardCount: counts.infoLibraryCardCount,
          journalEntryCount: counts.journalEntryCount
        }
        return true
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchActivityCounts')
        return false
      }
    },
    async fetchFullUserInfo() {
      try {
        const response = await httpClient.get(
          '/web/api/MyAccount/GetFullUserInfo'
        )

        if (!response?.data?.success)
          throw new Error(response.data.errorMessage)

        this.fullUserInfo = response.data.value
        this.fullUserInfoInit = true

        moment.tz.setDefault(this.fullUserInfo.timeZoneNameForMomentJs)

        return true
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchFullUserInfo')

        return false
      }
    },
    async fetchAppSettings() {
      try {
        if (this.appSettings == null) {
          const url = '/web/api/AppSettings/GetAppSettings'
          const response = await httpClient.get(url)

          if (!response?.data?.success) {
            throw new Error(response?.data?.errorMessage)
          }
          this.appSettings = response.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchAppSettings')
      }
    },
    async setLoading(payload: boolean) {
      //timer removed; TPLayout children are not mounted until pre-fetch is complete
      this.loading = payload
    },
    async setPageHeaders(payload: IPageHeader) {
      this.pageHeaders = payload
    },
    async setSubmitErrorMethod(payload: ISubmitError) {
      this.submitError = payload
    },
    async setSetObserverValMethod(payload: boolean) {
      this.setObserverVal = payload
    },
    async addPendingOpMethod(payload: Promise<unknown>) {
      this.pendingOperations.push(payload)
      const cleanup = () =>
        this.pendingOperations.splice(
          this.pendingOperations.indexOf(payload),
          1
        )
      payload.then(cleanup).catch(cleanup)
    },
    async cancelRequest() {
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = null
    },
    formatDate(
      value: string | Date | Moment | undefined | null,
      withTime = true
    ) {
      if (value == null || value == undefined) return ''
      let dateFormat = this.fullUserInfo.shortDateFormat?.toUpperCase()
      if (withTime) {
        dateFormat += ' [at] hh:mm A'
      }
      if (typeof value == 'string' || moment.isDate(value)) {
        return moment
          .utc(value)
          .tz(this.fullUserInfo.timeZoneNameForMomentJs ?? 'UTC')
          .format(dateFormat ?? 'MM/DD/YYYY')
      } else {
        return value
          .clone()
          .tz(this.fullUserInfo.timeZoneNameForMomentJs ?? 'UTC')
          .format(dateFormat ?? 'MM/DD/YYYY')
      }
    },
    formatTimeStamp(value: string | Date | Moment | undefined | null) {
      if (value == null || value == undefined) return ''
      let dateFormat = this.fullUserInfo.shortDateFormat?.toUpperCase()
      dateFormat += ', hh:mm A'
      if (typeof value == 'string' || moment.isDate(value)) {
        return moment
          .utc(value, 'YYYY-MM-DD HH:mm:ss')
          .tz(this.fullUserInfo.timeZoneNameForMomentJs || 'UTC')
          .format(dateFormat)
      } else {
        return value
          .clone()
          .tz(this.fullUserInfo.timeZoneNameForMomentJs ?? 'UTC')
          .format(dateFormat)
      }
    },
    formatFromUtcTime(value: string | Date | Moment | undefined | null) {
      const utc = moment.utc(value)
      return moment(utc)
        .tz(this.fullUserInfo.timeZoneNameForMomentJs ?? 'UTC')
        .format('h:mm A')
    },
    formatDateInUtcNoTZ(value: string | Date | Moment | undefined | null) {
      const utc = moment.utc(value)
      return moment(utc).format('MM/DD/YYYY')
    },
    formatDateFromNow(value: string | Date | Moment | undefined | null) {
      if (value == null || value == undefined) return ''

      if (typeof value == 'string' || moment.isDate(value)) {
        return moment(value, 'YYYY-MM-DD HH:mm:ss').fromNow()
      } else {
        return value.clone().fromNow()
      }
    },
    setLayoutCommonDataLoaded(val: boolean) {
      this.layoutCommonDataLoaded = val
    },
    redactPhoneNumber(phoneNum: string) {
      if (phoneNum?.length == 12 && phoneNum?.startsWith('+1')) {
        // get the first digit of the area code
        const areaCodeFirstNumber = phoneNum.substring(2, 3)
        // get the last 2 digits of the line number
        const lineLastTwoNumbers = phoneNum.substring(10)
        return `+1 (${areaCodeFirstNumber}**) ***-**${lineLastTwoNumbers}`
      }
      return phoneNum
    },
    removeFirstToast() {
      this.toastList.shift()
    },
    setErrorModal(value: IErrorModal) {
      this.errorModal = value
    },
    resetErrorModal() {
      this.errorModal = {
        showErrorModal: false,
        title: '',
        errorMessage: '',
        showFixError: false,
        showGenericError: false
      }
    },
    isToday(first: Date) {
      const firstMoment = moment.utc(first)
      const secondMoment = moment.utc()

      return firstMoment.isSame(secondMoment, 'day')
    },
    isBannerCloseCooldownEnded(featureName: UpgradeFeatureName): boolean {
      if (this.upgradeCTA[featureName].isClosed) {
        const timestamp = moment(this.upgradeCTA[featureName].closedWhen)
        // sets isClosed to false when timestamp is older than 30 days
        if (
          moment().diff(timestamp, 'days') >=
          constants.UPGRADE_BANNER_COOLDOWN_IN_DAYS
        ) {
          this.upgradeCTA[featureName].isClosed = false
          return true
        }
        return false
      } else {
        return true
      }
    },
    setShowingNoCount(value: boolean) {
      this.showingNoCount = value
    },
    setLayoutOptions(value: ILayoutOptions) {
      const _options = {
        pageType: constants.pageType.list,
        headerText: '',
        subHeaderText: '',
        showBackbutton: false,
        showPaymentButtons: false,
        backButtonRouteName: undefined,
        backButtonLabel: 'go back',
        showUpgradeBanner: false,
        showAddNewButton: true,
        showHeaderAvatar: false,
        showCalendarNotificationsButton: false,
        showInfoButton: false,
        upgradeBannerForewordMessage:
          'Attachments are archived seven days after they are uploaded.',
        upgradeBannerMessageTier0: 'for unlimited access to all attachments.',
        upgradeBannerMessageTier1: undefined,
        showShadow: true,
        showMobileHeader: true,
        appLayoutHeightOfViewport: false
      }
      this.layoutOptions = { ..._options, ...value }
    },
    setAdSenseKeys(desktop: string, mobile: string) {
      this.adsenseDesktopKey = desktop
      this.adsenseMobileKey = mobile
    },
    setWindowWidthChanges() {
      this.windowWidth = window.innerWidth
    },
    setAdblockModalShown(val: boolean) {
      this.adblockModalShown = val
    },
    setUpdateServiceWorker(val: string) {
      localStorage.setItem('newVersionAvailable', val)
    },
    setShowInfoButtonLayoutOption(val: boolean) {
      this.layoutOptions = { ...this.layoutOptions, showInfoButton: val }
    },
    setWelcomePageViewed(featureName: string, isViewed: boolean) {
      this.welcomePagesViewed = {
        ...this.welcomePagesViewed,
        [featureName]: isViewed
      }
    },
    setshowInfoModal(val: boolean) {
      this.showInfoModal = val
    },
    setMfaSmsEnabled(mfaSmsEnabled: boolean) {
      this.fullUserInfo.mfaSmsEnabled = mfaSmsEnabled
    },
    setFullUserInfoInit(fullUserInfoInit: boolean) {
      this.fullUserInfoInit = fullUserInfoInit
    },
    setShowMobileHeader(showMobileHeader: boolean) {
      this.layoutOptions.showMobileHeader = showMobileHeader
    },
    setSearchMode(searchMode: boolean) {
      this.searchMode = searchMode
    },
    setUpgradeCTA(
      featureName: UpgradeFeatureName,
      closeCTA: boolean,
      timestamp: string
    ) {
      this.upgradeCTA[featureName].closedWhen = timestamp
      this.upgradeCTA[featureName].isClosed = closeCTA
      this.layoutOptions.showUpgradeBanner = !closeCTA
    },
    setUserLocale(locale: string) {
      this.userLocale = locale
    },
    setBreadcrumbs(val: IBreadcrumbEvent[]) {
      this.pageHeaders.pageBreadcrumbs = val
    },
    pushBreadcrumb(val: IBreadcrumbEvent) {
      this.pageHeaders.pageBreadcrumbs?.push(val)
    },
    popBreadcrumb() {
      return this.pageHeaders.pageBreadcrumbs?.pop()
    },
    setCurrentFolderBreadcrumbData(data: unknown, crumbName?: string) {
      //current breadcrumb folder is a copy from the original (from .slice)
      const currentFolder = this.pageHeaders.pageBreadcrumbs?.find(
        (b) =>
          b.breadcrumbData?.itemID ==
          this.currentBreadcrumbFolder?.breadcrumbData.itemID
      )

      if (currentFolder) {
        currentFolder.breadcrumbData = data
        currentFolder.crumbName = crumbName ?? currentFolder.crumbName
      }
    },
    async fetchFirebaseAuthToken() {
      try {
        const url = '/mobile/api/Token/IssueFirebaseToken'
        const response = await httpClient.post(url)

        if (!response?.data) {
          throw new Error(response?.data?.errorMessage)
        }
        this.fbAuthToken = response.data.firebaseToken
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchAppSettings')
      }
    },
    setAppLayoutHeightOfViewport(heightOfViewport: boolean) {
      this.layoutOptions.appLayoutHeightOfViewport = heightOfViewport
    },
    setSearchTerm(term: string) {
      this.searchTerm = term
    },
    startDownload(url: string, fileName: string, mimeType?: string) {
      const id = Date.now().toString() // Generate a unique ID for the download

      WorkerService.createWorker(id, fileDownloaderWorker)

      const options: RequestInit = {}
      const request: WorkerRequest = { url, options, id }

      WorkerService.postRequest(id, request, (response) => {
        if (response.success) {
          if (response.data.status === 'progress') {
            if (response.data.id === id) {
              const download = this.getDownloadFromList(id)
              if (download) {
                download.progress = response.data.progress
              }
            }
          } else if (response.data.status === 'completed') {
            const download = this.getDownloadFromList(id)
            if (download) {
              this.handleDownloadedFile(response.data.data, download.fileName)
              download.status = DownloadStatus.Completed
            }
            WorkerService.terminateWorker(id)
          } else if (response.data.status === 'error') {
            const download = this.getDownloadFromList(id)
            if (download) {
              download.status = DownloadStatus.Failed
            }
            WorkerService.terminateWorker(id)
          }
        } else {
          const download = this.getDownloadFromList(id)
          if (download) {
            download.status = DownloadStatus.Failed
          }
        }
      })

      this.downloads.push({
        id,
        url,
        progress: 0,
        fileName,
        status: DownloadStatus.Downloading,
        mimeType: mimeType
      })
    },
    reDownload(existingDownload: IDownload) {
      WorkerService.createWorker(existingDownload.id, fileDownloaderWorker)

      const options: RequestInit = {}
      const request: WorkerRequest = {
        url: existingDownload.url,
        options,
        id: existingDownload.id
      }

      WorkerService.postRequest(existingDownload.id, request, (response) => {
        if (response.success) {
          if (response.data.status === 'progress') {
            if (response.data.id === existingDownload.id) {
              const download = this.getDownloadFromList(existingDownload.id)
              if (download) {
                download.progress = response.data.progress
              }
            }
          } else if (response.data.status === 'completed') {
            const download = this.getDownloadFromList(existingDownload.id)
            if (download) {
              this.handleDownloadedFile(response.data.data, download.fileName)
              download.status = DownloadStatus.Completed
            }
            WorkerService.terminateWorker(existingDownload.id)
          } else if (response.data.status === 'error') {
            const download = this.getDownloadFromList(existingDownload.id)
            if (download) {
              download.status = DownloadStatus.Failed
            }
            WorkerService.terminateWorker(existingDownload.id)
          }
        } else {
          const download = this.getDownloadFromList(existingDownload.id)
          if (download) {
            download.status = DownloadStatus.Failed
          }
        }
      })
    },
    handleDownloadedFile(blob: Blob, fileName: string) {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    },
    stopDownload(id: string) {
      const download = this.getDownloadFromList(id)

      if (download) {
        download.status = DownloadStatus.Stopped
        WorkerService.terminateWorker(id)
      }
    },
    removeDownloadFromList(id: string) {
      const downloadIndex = this.downloads.findIndex((d) => d.id === id)

      if (downloadIndex !== -1) {
        WorkerService.terminateWorker(id)
        this.downloads.splice(downloadIndex, 1)
      }
    },
    getDownloadFromList(id: string) {
      return this.downloads.find((d) => d.id === id)
    },
    removeAllDownloads() {
      this.downloads.forEach((d) => {
        if (d.status == DownloadStatus.Downloading) {
          WorkerService.terminateWorker(d.id)
        }
      })

      this.downloads = []
    },
    cancelAllDownloads() {
      const downloadString = `<span class="place-center">${i18n.global.t('downloads.inProgressCancelModal.content')}</span>`

      if (this.inProgressDownloads.length > 0) {
        const { createSlot, closeModal, generateModal, HTMLtoComponent } =
          useModals()

        const el = generateModal({
          default: {
            headerText: i18n.global.t('downloads.inProgressCancelModal.header'),
            footerButtonLabel: i18n.global.t(
              'downloads.inProgressCancelModal.confirmButton'
            )
          },
          slot: {
            content: createSlot('content', HTMLtoComponent(downloadString))
              .content
          },
          config: {
            showHeader: true,
            showBody: true,
            showFooter: true,
            addContentPadding: true,
            closeOnConfirm: true,
            showCloseButton: true,
            secondaryCTALabel: i18n.global.t(
              'downloads.inProgressCancelModal.cancelButton'
            )
          },
          callback: {
            confirmFn: () => {
              this.removeAllDownloads()
            },
            secondaryFn: () => {
              closeModal(el)
            }
          }
        })
      } else {
        this.removeAllDownloads()
      }
    },
    setMobileHeaderMounted(mounted: boolean) {
      this.mobileHeaderMounted = mounted
    },
    setShowAddNewButtonLayoutOption(show: boolean) {
      this.layoutOptions.showAddNewButton = show
    },
    setUnseenVoicemailBadgecount(count: number) {
      this.badgeCounts = {
        ...this.badgeCounts,
        unseenVoicemailCount: count
      }
    },
    getFromIndexedDB(
      objectStore: string,
      key: string,
      value: any,
      template?: object
    ) {
      //objectStore is the "table", key is the "column name", and value is the "column value"
      const id = Date.now().toString()

      WorkerService.createWorker(id, indexedDBWorker)

      const request: IndexedDBWorkerRequest = {
        id: id,
        database: 'tp',
        objectStore: objectStore,
        key: key,
        value: [...value],
        options: {
          method: 'GET'
        },
        template: template
      }

      const status = {
        id: id,
        success: null,
        method: 'GET'
      }

      WorkerService.postRequest(id, request, (response) => {
        if (response.success) {
          this.updateIndexedDBStatus({
            ...status,
            success: true,
            data: response.data
          })
          return
        }

        this.updateIndexedDBStatus({
          ...status,
          success: false,
          data: response.data
        })
      })

      this.addToIndexedDBStatuses(status)

      //so progress can be watched
      return id
    },
    postToIndexedDB(objectStore: string, key: string, body: any) {
      const id = Date.now().toString()

      WorkerService.createWorker(id, indexedDBWorker)

      const request: IndexedDBWorkerRequest = {
        id: id,
        database: 'tp',
        objectStore: objectStore,
        key: key,
        options: {
          method: 'POST',
          body: body
        }
      }

      const status = {
        id: id,
        success: null,
        method: 'POST'
      }

      WorkerService.postRequest(id, request, (response) => {
        if (response.success) {
          this.updateIndexedDBStatus({
            ...status,
            success: true,
            data: response.data
          })
          return
        }

        this.updateIndexedDBStatus({
          ...status,
          success: false,
          data: response.data
        })
      })

      this.addToIndexedDBStatuses(status)

      return id
    },
    putToIndexedDB(objectStore: string, key: string, body: any) {
      const id = Date.now().toString()

      WorkerService.createWorker(id, indexedDBWorker)

      const request: IndexedDBWorkerRequest = {
        id: id,
        database: 'tp',
        objectStore: objectStore,
        key: key,
        options: {
          method: 'PUT',
          body: body
        }
      }

      const status = {
        id: id,
        success: null,
        method: 'PUT'
      }

      WorkerService.postRequest(id, request, (response) => {
        if (response.success) {
          this.updateIndexedDBStatus({
            ...status,
            success: true,
            data: response.data
          })
          return
        }

        this.updateIndexedDBStatus({
          ...status,
          success: false,
          data: response.data
        })
      })

      this.addToIndexedDBStatuses(status)

      return id
    },
    deleteFromIndexedDB(objectStore: string, key: string, value: any) {
      const id = Date.now().toString()

      WorkerService.createWorker(id, indexedDBWorker)

      const request: IndexedDBWorkerRequest = {
        id: id,
        database: 'tp',
        objectStore: objectStore,
        key: key,
        value: value,
        options: {
          method: 'DELETE'
        }
      }

      const status = {
        id: id,
        success: null,
        method: 'DELETE'
      }

      WorkerService.postRequest(id, request, (response) => {
        if (response.success) {
          this.updateIndexedDBStatus({
            ...status,
            success: true,
            data: response.data
          })
          return
        }

        this.updateIndexedDBStatus({
          ...status,
          success: false,
          data: response.data
        })
      })

      this.addToIndexedDBStatuses(status)

      return id
    },
    addToIndexedDBStatuses(status: IIndexedDBStatus) {
      this.indexedDBStatuses.push(status)
    },
    removeFromIndexedDBStatuses(id: string) {
      this.indexedDBStatuses = this.indexedDBStatuses.filter((s) => s.id != id)
    },
    getIndexedDBStatus(id: string) {
      return this.indexedDBStatuses.find((s) => s.id == id)
    },
    updateIndexedDBStatus(status: IIndexedDBStatus) {
      const index = this.indexedDBStatuses.findIndex((s) => s.id == status.id)

      this.indexedDBStatuses[index] = status
    },
    checkSalt() {
      return !!storageService.getFromLocalStorage('salt')
    },
    updateSalt() {
      const array = new Uint8Array(16)

      const salt = new TextDecoder().decode(
        window.crypto.getRandomValues(array)
      )

      storageService.saveToLocalStorage({
        key: 'salt',
        value: salt,
        local: true
      })
    },
    async fetchAndProcessFeatureFlags() {
      console.log('getting feature flags')
      await fetchAndActivate()
      const _existingFlags = this.featureFlags

      this.featureFlags = getAllFeatureFlagValues()

      // check if there are new/different values and update newFeatureFlagValues (only run after initial loading)
      if (_existingFlags) {
        this.newFeatureFlagValues =
          JSON.stringify(_existingFlags) != JSON.stringify(this.featureFlags)
        console.log('newFeatureFlagValues = ' + this.newFeatureFlagValues)
      }
    },
    setAppInsightsInstance(instance: ApplicationInsights) {
      this.appInsightsInstance = instance
    },
    flushAppInsights() {
      this.appInsightsInstance?.flush(false)
    },
    setAppInsightsDisableTelemetry(disable: boolean) {
      if (!this.appInsightsInstance) {
        return
      }

      this.appInsightsInstance.config.disableTelemetry = disable
    },
    setDoAddNew(doAddNew: boolean | null) {
      this.doAddNew = doAddNew
    },
    setHeaderText(headerText?: string) {
      this.layoutOptions.headerText = headerText
    }
  }
})
