// src/services/realtimeDatabaseService.ts
import { app, database } from './firebase.ts'
import { ref, set, get, remove, child, onValue, off } from 'firebase/database'
import {
  getAuth,
  signInWithCustomToken,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence
} from 'firebase/auth'
import { getAnalytics, setUserProperties } from 'firebase/analytics'

class RealtimeDatabaseService {
  private db = database

  async authenticateUser(token: string) {
    const auth = getAuth()
    try {
      await setPersistence(auth, browserSessionPersistence)
      await signInWithCustomToken(auth, token)
      this.watchForUserStatusChange()
      return true
    } catch (error) {
      console.error('Authentication failed:', error)
      return false
    }
  }

  async watchForUserStatusChange() {
    const auth = getAuth()
    const analytics = getAnalytics(app)
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User authenticated successfully: ' + JSON.stringify(user))
        const uid = user.uid
        // Set a custom user property
        setUserProperties(analytics, {
          custom_user_id: uid,
          user_role: 'beta_tester'
        })

        console.log('set properties: ' + JSON.stringify(analytics))
        return true
      } else {
        // console.log('User is not authenticated')
        return false
      }
    })
  }

  // Write data to a specified path
  async writeData<T>(path: string, data: T): Promise<void> {
    try {
      // console.log('Calling DB!')

      await set(ref(this.db, path), data)
        .then(() => {
          // Data saved successfully!
          console.log('Data saved successfully!')
        })
        .catch((error) => {
          console.error('Error saving data: ', error)
        })
      // console.log('Data saved successfully!')
    } catch (error) {
      console.error('Error saving data: ', error)
    }
  }

  // Read data from a specified path
  async readData<T>(path: string): Promise<T | null> {
    try {
      const snapshot = await get(child(ref(this.db), path))
      if (snapshot.exists()) {
        return snapshot.val() as T
      } else {
        // console.log('No data available at this path!')
        return null
      }
    } catch (error) {
      console.error('Error reading data: ', error)
      return null
    }
  }

  // Read data from a specified path
  async deleteData(path: string): Promise<void> {
    try {
      remove(child(ref(this.db), path))
    } catch (error) {
      console.error('Error deleting data: ', error)
    }
  }

  // Listen for new or updated data at a specified path
  listenForNewEntries<T>(
    path: string,
    callback: (data: T | null) => void,
    maxRetries = 5,
    retryDelay = 2000
  ): () => void {
    const dbRef = ref(this.db, path)
    let unsubscribe: () => void

    const tryListening = (retryCount: number) => {
      if (retryCount > maxRetries) {
        console.error('Max retries reached. Listener not established.')
        return
      }

      unsubscribe = onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.exists()) {
            callback(snapshot.val() as T)
          } else {
            callback(null)
          }
        },
        (error) => {
          console.error('Permission denied. Retrying...', error)
          setTimeout(() => tryListening(retryCount + 1), retryDelay)
        }
      )
    }

    try {
      tryListening(0)
    } catch (error) {
      console.error('Error listenForNewEntries: ', error)
      // If an error occurs, define unsubscribe as a no-op function
      unsubscribe = () => off(dbRef)
    }

    // Return the unsubscribe function
    return () => unsubscribe()
  }
}

export const realtimeDatabaseService = new RealtimeDatabaseService()
