import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

import {
  getRemoteConfig,
  getValue as getValueLibrary,
  fetchAndActivate as fetchAndActivateLibrary,
  getAll as getAllLibrary
} from 'firebase/remote-config'
import type { IFeatureFlags } from '@/models/interfaces'

const isDev = import.meta.env.MODE === 'development'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_APIKEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: import.meta.env.VITE_APP_FIREBASE_DATABASEURL,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECTID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_SENDERID,
  appId: import.meta.env.VITE_APP_FIREBASE_APPID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENTID
}

const app = initializeApp(firebaseConfig)
const database = getDatabase(app)
const remoteConfig = getRemoteConfig(app)

// Define default values for feature flags and update the IFeatureFlags in interfaces.ts
// feature flags being added to Firebase console should have a default value set here.
const defaultFeatureFlags: IFeatureFlags = {
  webFeatureFlagTest: false,
  web_messages_enable3ColumnLayout: false
}

// 43200000 = 12 hours (The default and recommended production fetch interval for Remote Config is 12 hours)
remoteConfig.settings.minimumFetchIntervalMillis = isDev ? 60 : 43200000

remoteConfig.defaultConfig = defaultFeatureFlags as unknown as {
  [key: string]: string | number | boolean
}

const fetchAndActivate = () => fetchAndActivateLibrary(remoteConfig)
const getValue = (key: string) => getValueLibrary(remoteConfig, key)
const getFeatureFlagValue = (key: string) => getValue(key).asBoolean()
const getAll = () => getAllLibrary(remoteConfig)
const getAllFeatureFlagValues = () => {
  // Use Object.keys to dynamically fetch all values
  const updatedFeatureFlags: IFeatureFlags = Object.keys(
    defaultFeatureFlags
  ).reduce((acc, key) => {
    // TypeScript safe casting since keys are known
    const typedKey = key as keyof IFeatureFlags
    acc[typedKey] = getValue(typedKey).asBoolean()
    return acc
  }, {} as IFeatureFlags)

  return updatedFeatureFlags
}
export type { IFeatureFlags }
export {
  app,
  database,
  remoteConfig,
  fetchAndActivate,
  getValue,
  getFeatureFlagValue,
  getAll,
  getAllFeatureFlagValues
}
